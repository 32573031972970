// notificationUtils.js
import { notification } from "antd"; // Import any necessary dependencies

export const openNotification = (
  notification_type,
  notification_message,
  notification_description
) => {
  notification.open({
    type: notification_type,
    message: notification_message,
    description: notification_description,
    duration: 2,
  });
};
