import { Header } from "../../shared/Header/Header";
import { Col, Row, Divider,Button, Space } from 'antd';
import '../../assets/scss/auth.scss';
import qrcode from "../../assets/img/svg/qrcodeline.svg";
import grayflight from '../../assets/img/svg/flights-light.svg'
import flight from '../../assets/img/svg/flights.svg'
import { useEffect, useState } from "react";
import { AiBots } from "../../services/aiBot";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../Pages/Notification/notificationUtils";

export default function UpiPayment() {
  const { flightData } = AiBots();

const [ flightDataVal , setFlightData ] = useState([]);
const navigate = useNavigate()
  useEffect(()=>{
    flightData().then((res)=>{
      if(res){
        setFlightData(res)
      }
    })
  }, []);
 const  navigateVal = () => {
  navigate("/home/1")
  }

  const  cancelBooking = () => {
    openNotification(
      "success",
      "Booking Cancelled",
      "Flight Booking Cancelled"
    );
    navigate("/");

    }
  return (
    <>
      <div className="upilayout">
        <Row>
          <Col xl={17}>
            <div className="upilayout__payment">
              <div className="upilayout__main-title">UPI Payment Page</div>
              <div className="upilayout__para">Pay Directly From Your Bank Account</div>
              <div className="upilayout__scan-pay">
                <Row>
                  <Col xl={10}>
                  <div className="h-100">
                      <div className="upilayout__sub-title">Scan  and pay</div>
                      <div className="upilayout__sub-para">Scan & pay using any banking app</div>
                      <div className="upilayout__scanQR">
                        <img src={qrcode} alt="" />
                      </div>
                    </div>
                  </Col>
                  <Col xl={2}>
                  <div className="upilayout__divieder">
                      <div class="vertical-divider">
                        <div class="center-element">OR</div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={10}>
                    <div className="h-100">
                      <div className="upilayout__sub-title">Enter UPI ID</div>
                      <input className="upilayout__input-box" type="text" />
             
                      <div>
                           <Button className="upilayout__verify-btn" onClick={navigateVal}>Verify</Button>
                      </div>
                      <div className="upilayout__instruction">
                        <ul>
                          <li> Enter your registered VPA</li>
                          <li>Receive payment request on bank app </li>
                          <li>Authorize payment request</li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xl={7}>
            <div className="upilayout__booking">
              <div className="upilayout__booking-header">
                 <h6>Your Booking</h6>
              </div>
              <div className="upilayout__booking-header">
                 <div className="upilayout__flight-light">
                  <img src={grayflight} alt="" />
                  Flight Travel
                 </div>
                 <h4>From Penang</h4>
                   <div className="upilayout__flight-from">
                   14 Sept
                   <img src={flight} alt="" />
                    15 Sept
                   </div>
                   <div className="upilayout__flight-from">
                   1 Night & 1 Day
                   </div>
              </div>
              <div className="upilayout__booking-header">
                 <h3>Fare Summary</h3>
                  <div className="upilayout__justify">
                    <div className="upilayout__gst">
                    Basic Cost <br />
                    Incl. GST
                    </div>
                    <div className="upilayout__price" >
                    140.42 MYR
                    </div>
                  </div>
              </div>
              <div className="upilayout__booking-headerlast">
                  <div className="upilayout__justify">
                    <div className="upilayout__gst">
                    Payable amount
                    </div>
                    <div className="upilayout__gst">
                    140.42 MYR
                    </div>
                   
                  </div>
                  <div>
                           <Button className="upilayout__cancel-btn" onClick={cancelBooking}>Cancel</Button>
                      </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
