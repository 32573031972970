import axios from "axios";


const LiveUrl = "https://airlines-api.blockchainfirm.io/";
const LocalUrl = "http://localhost:5000/"

export const baseUrl = LocalUrl;

const url = axios.create({
  baseURL: LiveUrl,
});

// http://10.129.21.82:5000/
// http://localhost:5000/
// https://airlines-api.blockchainfirm.io/

url.interceptors.request.use(
  function (config) {
    // config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
url.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error?.response?.status === 401) {
      console.log(error);
      return;
    }
    if (error && error?.response?.status === 403) {
      console.log(error);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export default url;
