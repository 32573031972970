import MainUrl from "../shared/constant/url/mainUrl";
// import AuthUrl from "../shared/constant/url/authUrl";

import { Constants } from "../shared/constant/constant";
export const AiBots = () => {
  const constants = Constants();

  const register = (val) => {
    return MainUrl.post(constants.register, val);
  };
  const login = (val) => {
    return MainUrl.post(constants.login, val);
  };
  const logout = (val) => {
    return MainUrl.patch(constants.logout, val);
  };
  const aiChat = (val) => {
    console.log(constants.aiChat, val);
    return MainUrl.post(constants.aiChat, val);
  };


  const airlinesChat = (val) => {
    return MainUrl.post(constants.airlinesChat, val);
  }
  const aiImage = (val) => {
    console.log(constants.aiImage, val);
    return MainUrl.post(constants.aiImage, val);
  };

  const storeDb = (val) => {
    console.log(constants.storeDb, val);
    return MainUrl.post(constants.storeDb, val);
  };
  const aiOwnChatbot = (val) => {
    console.log(constants.aiOwnChatbot, val);
    return MainUrl.post(constants.aiOwnChatbot, val);
  };
  const aiFile = (val) => {
    console.log(constants.aiFile, val);
    return MainUrl.post(constants.aiFile, val);
  };
  const aiUrl = (val) => {
    console.log(constants.aiUrl, val);
    return MainUrl.post(constants.aiUrl, val);
  };
  const textlen = (val) => {
    console.log(constants.textlen, val);
    return MainUrl.get(constants.textlen, val);
  };

  const flightData = () => {
    console.log(MainUrl + constants.flightData )
    return MainUrl.get(constants.flightData);
  }
  const searchFlights = (val) =>{
    return MainUrl.post(constants.searchFlights, val);
  }

  const sugggestionAirport = (val) =>{
    console.log(val)
    return MainUrl.post(constants.suggestAirport, val);
  }
  const fetchHotels = (val) =>{
    console.log(val)
    return MainUrl.post(constants.hotelList, val);
  }
  const resetHistory = (val) =>{
    console.log(val)
    return MainUrl.get(constants.resetHistory, val);
  }
  return {
    register,
    login,
    logout,
    aiChat,
    aiImage,
    airlinesChat,
    storeDb,
    aiOwnChatbot,
    aiFile,
    aiUrl,
    textlen,
    flightData,
    searchFlights,
    sugggestionAirport,
    fetchHotels,
    resetHistory
  };
};
