import "./Dashboard.scss";
import { Form, Input, Button, Tooltip, message, Upload, Progress } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import chat from "../../../assets/img/svg/chat-black.svg";
import chatwhite from "../../../assets/img/svg/chat-white.svg";
import chatgray from "../../../assets/img/svg/chat-gray.svg";
import upload from "../../../assets/img/svg/upload.svg";
import imgwhite from "../../../assets/img/svg/img-white.svg";
import imgBlack from "../../../assets/img/svg/chat-black.svg";
import imggray from "../../../assets/img/svg/img-gray.svg";
import dataimg from "../../../assets/img/svg/dataimg-gray.svg";
import deleteimg from "../../../assets/img/svg/delete.svg";
import { AiBots } from "../../../services/aiBot";
import { useNavigate } from "react-router-dom";
import ChatBot from "../ChatBot/ChatBot";
import SearchButton from "../SearchButton/SearchButton";
import DataGeneration from "../DataGeneration/DataGeneration";
import { openNotification } from "../Notification/notificationUtils";
import { da } from "date-fns/locale";

export default function Dashboard() {
  const { TextArea } = Input;
  const [dataGenerationIsShown, setDataGenerationIsShown] = useState(false);
  const [arrowDown, setArrowDown] = useState(true);
  const [userInput, setUserInput] = useState("");
  const [clear, setClear] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [IsShown, setIsShown] = useState(true);
  const [searchButton, setSearchButton] = useState(false);

  const userTextRef = useRef(null);
  const aiTextRef = useRef(null);
  const chatboxRef = useRef(null);
  const [textLength, setTextLength] = useState(0);
  const { aiChat, aiImage, aiFile, aiText, aiUrl } = AiBots();
  const [imageSrc, setImageSrc] = useState("");
  const [tooltiptext, setTooltiptext] = useState("Copy");
  const [Fileupload, setFileupload] = useState();
  const [firstTimeActive, setFirstTimeActive] = useState(true);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("chat");
  const [active, setActive] = useState(false);
  const [DataGenerationTab, setDataGenerationTab] = useState("File");
  const [userInputFromSearch, setUserInputFromSearch] = useState("");
  const [userDataText, setUserDataText] = useState("");
  const [userDataGenerationTab, setUserGenerationTab] = useState("File");
  const [OwnbotStatus, setOwnbotStatus] = useState();


  const handleUserInput = (userInput) => {
    setFirstTimeActive(false);
    setUserInputFromSearch(userInput);
  };


  const handleOwnbotStatus = (ownbotStatus) => {
    setOwnbotStatus(ownbotStatus);
  };

  useEffect(() => {
    if (!localStorage.getItem("UserId")) {
      console.log("UserId");
      navigate("./auth/login");
    } else {
    }
  }, [textLength, chatHistory]);



  const handleClearMessages = async (data) => {
    console.log("dash===========>", data);
    setActive(data);
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard__card-input">
          <div className="dashboard__card">
            <ChatBot
              type={activeTab}
              userInput={active && firstTimeActive ? "Hi Start" : userInputFromSearch}
              data={handleClearMessages}
            />
          </div>
        </div>

        {active && (
          <SearchButton
            type={activeTab}
            onUserInput={handleUserInput}
            dataText={userDataText}
            DataGenerationTab={userDataGenerationTab}
            onOwnbotStatus={handleOwnbotStatus}
          />
        )}
      </div>
    </>
  );
}
