import { Header } from "../../shared/Header/Header"
import { Pagesrouter } from "../Pages/Pagesrouter"
export const Layout = () => {
  return (
    <>
      <Header></Header>
      <div className="container">
       <Pagesrouter></Pagesrouter>
      </div>
     
    </>
  )
}

