export const Constants = () => {
  const constant = {
    register: "user/signup",
    login: "user/signin",
    logout: "user/logout",
    aiChat: "/aiResponse/jarvis-chat",
    aiImage: "/aiResponse/jarvis-image",

    storeDb: "/aiResponse/jarvis-dataGeneration/store-text",
    aiOwnChatbot: "/aiResponse/jarvis-dataGeneration/OwnChatBot",
    aiFile: "/aiResponse/jarvis-dataGeneration/file",
    aiUrl: "/aiResponse/jarvis-dataGeneration/url",
    textlen: "/aiResponse/jarvis-chat/length",
    flightData: "/airlines/details",
    airlinesChat: "aiResponse/jarvis-airline-v2/OwnChatBot",
    searchFlights: "airlines/booking",
    suggestAirport: "airlines/iata",
    hotelList: "jarvis-airline/get_hotels",
    resetHistory : "resetConversationHistory"
  };

  return constant;
};
