import logo from "../../../assets/img/logo.svg";
import messagecircle from "../../../assets/img/icons/message-circle.svg";
import bookmark from "../../../assets/img/icons/book-open.svg";
import check from "../../../assets/img/icons/check.svg";
import trade from "../../../assets/img/icons/trending-up.svg";
import code from "../../../assets/img/svg/code.svg";
import audio from "../../../assets/img/svg/audio.svg";
import edu from "../../../assets/img/svg/edu.svg";
import photo from "../../../assets/img/svg/photo.svg";
import videoJarvis from "../../../assets/img/svg/video-jarvis.svg";
import arrowRight from "../../../assets/img/svg/arrow-narrow-right.svg";
import success from "../../../assets/img/svg/ep_success-filled.svg";
import cab from "../../../assets/img/svg/cab.svg";
import hotel from "../../../assets/img/svg/hotel-fill.svg";
import "../Dashboard/Dashboard.scss";
import { useNavigate, Link } from "react-router-dom";
import { router } from "react-router-dom";
import passportImg from "../../../assets/img/passport.png";
import { format } from "date-fns";
import {
  Form,
  Input,
  notification,
  DatePicker,
  Button,
  Tooltip,
  message,
  Upload,
  Progress,
  Radio,
  Timeline,
  AutoComplete,
  Divider,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import chat from "../../../assets/img/svg/chat-black.svg";
import chatwhite from "../../../assets/img/svg/chat-white.svg";
import chatgray from "../../../assets/img/svg/chat-gray.svg";
import upload from "../../../assets/img/svg/upload.svg";
import imgwhite from "../../../assets/img/svg/img-white.svg";
import imgBlack from "../../../assets/img/svg/chat-black.svg";
import imggray from "../../../assets/img/svg/img-gray.svg";
import dataimg from "../../../assets/img/svg/dataimg-gray.svg";
import deleteimg from "../../../assets/img/svg/delete.svg";
import send from "../../../assets/img/svg/send-line.svg";
import copy from "../../../assets/img/icons/copy.svg";
import avatar from "../../../assets/img/icons/sender-avatar.svg";
import arrow from "../../../assets/img/svg/arrow-down.svg";
import arrowup from "../../../assets/img/svg/chevron-down.svg";
import close from "../../../assets/img/svg/x.svg";
import { AiBots } from "../../../services/aiBot";
import flightIcon from "../../../assets/img/svg/flights.svg";
import flightIconlight from "../../../assets/img/svg/flights-light.svg";
import { useParams } from "react-router-dom";
import Pdf from "../../../assets/img/Eticket_58063797.pdf";

export default function ChatBot(props) {
  const [arrowDown, setArrowDown] = useState(true);
  const [clear, setClear] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [IsShown, setIsShown] = useState(true);
  const [searchButton, setSearchButton] = useState(false);
  const [form] = Form.useForm();
  const userTextRef = useRef(null);
  const aiTextRef = useRef(null);
  const chatboxRef = useRef(null);
  const {
    aiChat,
    airlinesChat,
    searchFlights,
    sugggestionAirport,
    fetchHotels,
    resetHistory,
  } = AiBots();
  const [tooltiptext, setTooltiptext] = useState("Copy");
  const [propmtText, setPromptText] = useState("");
  const params = useParams();
  const [showTicket, setShowTicket] = useState(false);
  const [showSelect, setselect] = useState(false);
  const [showCar, setShowCar] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [jarvisForm, setjarvisForm] = useState(false);

  const [formVal, setForm] = useState({
    from: undefined,
    to: undefined,
    departureDate: "",
    return: "",
    passengerCount: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (props.userInput) {
      sendMessage();
    }
  }, [props.userInput]);

  useEffect(() => {
    if (!IsShown) {
      if (document.getElementById("chatbox")) {
        console.log(
          document.getElementById("chatbox")?.scrollHeight,
          window.innerHeight
        );
        if (
          document.getElementById("chatbox")?.scrollHeight > window.innerHeight
        ) {
          setShowArrow(true);
          handleArrowDownClick();
        } else {
          setShowArrow(false);
        }
      }
    }
  }, [chatboxRef, IsShown, document.getElementById("chatbox")?.scrollHeight]);
  useEffect(() => {
    if (params?.id) {
      setShowTicket(true);
      setIsShown(false);
    } else {
      setShowTicket(false);
      setIsShown(true);
    }
  }, []);

  const [selectedFlightDetails, setSelectedFlightDetails] = useState([]);
  const [flightSelected, setFightSelected] = useState(false);
  const selectedFlight = (data) => {
    setSelectedFlightDetails(data);
    setFightSelected(true);
  };

  // Initial check on page load

  const handleClearMessagesError = () => {
    setChatHistory([]);
    closeArrow();
    setSearchButton(false);
    setIsShown(true);
  };
  const handleClearMessages = async () => {
    setChatHistory([]);
    setjarvisForm(false)
    props.data(false)
    setFlightDetails([]);
    setShowFlightList(false);
    try {
      await resetHistory();
      console.log("handleClearMessages.data=====================>");
    } catch (error) {
      console.log(error);
    }
    closeArrow();
    setSearchButton(false);
    setIsShown(true);
    openNotification("info", "Notification", "All messages cleared");
    window.location.reload();
  };
  const handleArrowDownClick = () => {
    chatboxRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const openNotification = (
    notification_type,
    notification_message,
    notification_description
  ) => {
    notification.open({
      type: notification_type,
      message: notification_message,
      description: notification_description,
      duration: 3,
    });
  };
  const arrowClick = () => {
    setArrowDown(false);
    setClear(true);
  };

  const HandleCopyClick = (index) => {
    setTooltiptext("Copied!");
    const textToCopy = chatHistory[index].text;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setTimeout(() => {
          setTooltiptext("Copy");
        }, 1000);
      })
      .catch((error) => {
        openNotification("error", "Error!!", "Error on copying User text.");
      });
  };
  const resHandleCopyClick = (index) => {
    setTooltiptext("Copied!");
    const textToCopy = chatHistory[index].response;

    navigator.clipboard
      .writeText(textToCopy)

      .then(() => {
        setTimeout(() => {
          setTooltiptext("Copy");
        }, 1000);
      })
      .catch((error) => {
        openNotification("error", "Error!!", "Error on copying User text.");
      });
  };

  const closeArrow = () => {
    setArrowDown(true);
    const messageContent = document.querySelector(
      ".dashboard__message-content"
    );

    messageContent.classList.add("reverse-animation-class");

    setTimeout(() => {
      setClear(false);
    }, 500);
  };

  const handleSubmit = () => {
    setIsShown(false);
    form.resetFields();
  };

  const [hotels, setHotels] = useState([]);
  const getHotels = (val) => {
    console.log(val);
    setHotels([]);
    fetchHotels({ city_code: val }).then(
      async (response) => {
        if (response.status == 200) {
          // let suggestion = response['data']['airports']?.map((el)=> ({ value:el.name , id:el.iata_code}));
          // console.log(suggestion);
          setHotels(response["data"]);
        } else {
          setHotels([]);
        }
      },
      (err) => {
        setHotels([]);
      }
    );
  };

  const [showBoarding, setShowBoarding] = useState(false);
  const [showBoardingPnr, setShowBoardingPnr] = useState(false);

  const boardingPass = () => {
    // setShowBoarding(true);
    setIsShown(false);
    setShowBoardingPnr(true);
  };


  const isNumber = (val) => {
    var numbers = "^(([0-9]*)|(([0-9]*).([0-9]*)))$";
    return new RegExp(numbers).test(val);
  };

  const [loader, setloader] = useState(false);
  const [showFlightList, setShowFlightList] = useState(false);
  const [flightBookingDetails, setFlightBookingDetails] = useState([]);
  const Searchflight = (e, flight, bookingDetails) => {
    // e?.preventDefault()
    setloader(true);
    let val;
    console.log(flight);
    if (flight == false) {
      val = {
        departure: formVal.from_iata,
        arrival: formVal?.to_iata,
        departureDate: formVal?.departureDate,
        adults: formVal?.passengerCount,
      };
    }

    if (flight == true) {
      console.log(bookingDetails);
      val = bookingDetails;
    }

    searchFlights(val).then(
      (res) => {
        if (res) {
          if (res.status == 200) {
            console.log(res);
            setFlightDetails(res["data"]["flight_offers"]);
            setShowFlightList(true);
            setShowForm(false);
            setloader(false);
          } else {
            setFlightDetails([]);
          }
        }
      },
      (err) => {
        setFlightDetails([]);
      }
    );
  };

  const airportSuggestions = async (val) => {
    await setOptions([]);
    sugggestionAirport({ iata: val }).then(
      async (response) => {
        if (response.status == 200) {
          let suggestion = response["data"]["airports"]?.map((el) => ({
            value: el.name,
            id: el.iata_code,
          }));
          console.log(suggestion);
          setOptions(suggestion);
        } else {
          setOptions([]);
        }
      },
      (err) => {
        setOptions([]);
      }
    );
    //  },100)
  };

  const [flightDetails, setFlightDetails] = useState([]);
  const [bookingType, setBookingType] = useState([]);
  const [settingType, setsettingType] = useState(1);
  const [showFlightBookings, setShowFlightBookings] = useState(false);
  const sendMessage = async () => {
    console.log("DATA1========================>");
    setIsShown(false);
    setShowForm(false);
    if (showBoardingPnr !== true) {
      let message = {};
      if (props.userInput) {
        message = { text: props.userInput, response: "", isLoading: true };
      }
      if (propmtText?.length !== 0 && props.userInput?.length <= 0) {
        message = { text: propmtText, response: "", isLoading: true };
      }
      setChatHistory([...chatHistory, message]);
      setSearchButton(true);

      console.log("DATA1========================>", message.text);

      const response = await airlinesChat({ message: message.text })
        .then((Answer) => {
          var totalLength = 0;
          var totalReslength = 0;

          for (var i = 0; i < chatHistory.length; i++) {
            var textLength = chatHistory[i].text.length;
            totalLength += textLength;
          }
          for (var i = 0; i < chatHistory.length; i++) {
            var textLength = chatHistory[i].response.length;
            totalReslength += textLength;
          }

          setSearchButton(false);
          console.log("Answer.data========================>", Answer.data);
          return Answer.data;
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            openNotification(
              "error",
              "Network Error",
              "There was an error connecting to the server. Please try again later."
            );
            setSearchButton(false);
            setIsShown(true);
            handleClearMessagesError();
          } else {
            openNotification(
              "error",
              error.response.data.message,
              error.response.data.description
            );
            setIsShown(true);
            handleClearMessagesError();
            setSearchButton(false);
          }
        });

      // if(response.flight_id){
      //     setFlightDetails(response.flight_id)
      //  }

      await setBookingType(response.booking_type);
      await setsettingType(response.type);
      await setChatHistory((prevMessages) => [
        ...prevMessages.slice(0, -1), // Remove the previous incomplete message
        {
          text: props.userInput,
          response: response.reply,
          isLoading: false,
        },
      ]);
      if (response?.flight_offers && response?.flight_offers.length > 0) {
        await setFlightDetails(response?.flight_offers);
        await setShowFlightList(true);
        await setShowForm(false);
      }

      console.log(response?.Flight_details);
      setShowFlightBookings(response?.Flight_details);
      if (response?.Flight_details == true) {
        await setShowFlightList(true);
        if (response?.Flight_details == true) {
          await setShowFlightBookings(response?.Flight_details);
          await setFlightBookingDetails(response.booking_type);
          await Searchflight({}, true, response.booking_type);
          await setShowFlightList(true);
        }
      }
      if (response.type == "payment") {
        // setTimeout(()=>{
        navigate("/auth/upi");
        window.location.reload();
        // }, 1000)
      }
      let setResponse = {
        text: value,
        options: [],
        type: "",
      };

      if (value.includes("\n\n")) {
        //   let splittedResponse = (value.split("\n\n"));
        //   console.log(splittedResponse);
        //   let array = [];
        //   let textArray = []
        //   splittedResponse?.length > 0 && splittedResponse.forEach((el) => {
        //     console.log(el)
        //     if (el.includes('\n')) {
        //       // el.replace(/\n/g, '')
        //       array.push(el);
        //       console.log(array)
        //     } else {
        //       textArray.push(el)
        //     }

        //   });
        //   let val = array;
        //   let options = val[0].split(/\d+\.\s+/);
        //   options = options.map(option => option.replace(/\n/g, '').trim()).filter(option => option !== "");
        //   console.log(options);
        // Split the input into lines and remove empty lines
        const lines = value
          .split("\n")
          .filter((line) => line.trim().length > 0);
        // Initialize variables to store the parts of the output
        const output = [];
        let options = [];
        // Loop through the lines to construct the output
        for (const line of lines) {
          if (/^\d+\.\s/.test(line)) {
            // Lines starting with numbers and a period are considered options
            options.push(line);
          } else {
            // Other lines are part of the main text
            if (options.length > 0) {
              output.push(options); // Add options to the output
              options = []; // Reset options array
            }
            output.push([line]); // Add the current line to the output
          }
        }
        // Add any remaining options if they exist
        if (options.length > 0) {
          output.push(options);
        }
        const departureOptions = [];
        const instructions = [];
        for (const subArray of output) {
          for (const item of subArray) {
            if (item.match(/^\d+\.\s/)) {
              // If the item starts with a number and a period, it's a departure option
              departureOptions.push(item.replace(/^\d+\.\s/, ""));
            } else {
              // Otherwise, it's an instruction
              instructions.push(item);
            }
          }
        }
        let textValue = "";
        instructions.forEach((ele, i) => {
          textValue = textValue.concat(ele + "\n");
        });

        if (response.type !== "payment") {
          if (response.type !== "passengers") {
            setResponse.text = instructions;
            setResponse.options = departureOptions;
            setResponse.type = response.type;
          } else {
            options = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
            setResponse.text = instructions;
            setResponse.options = options;
            setResponse.type = response.type;
          }
        }
      } else if (value.includes("\n")) {
        let options = value[0].split(/\d+\.\s+/);
        options = options
          .map((option) => option.replace(/\n/g, "").trim())
          .filter((option) => option !== "");
        setResponse.text = options[0];
        setResponse.options = options.slice(1);
        setResponse.type = response.type;
        // setResponse.text = response;
      } else {
        let data = [];
        data.push(response.reply);
        setResponse.text = data;
        setResponse.options = [];
        setResponse.type = response.type;
      }

      // const responseText = JSON.parse(response);
      // const responseChars = responseText.split("");
      let responseInProgress = "";

      // responseChars.forEach((char, index) => {
      //   setTimeout(() => {
      //     responseInProgress += char;
      //     setChatHistory((prevMessages) => [
      //       ...prevMessages.slice(0, -1), // Remove the previous incomplete message
      //       {
      //         text: props.userInput,
      //         response: responseInProgress,
      //         isLoading: false,
      //       },
      //     ]);

      //     const chatWindow = document.querySelector(".chat-window");
      //     chatWindow.scrollTop = chatWindow.scrollHeight;

      //     if (index === responseChars.length - 1) {
      //       // Once the response is fully displayed, you can perform any additional actions here
      //     }
      //   }, (index + 1) * 50); // Adjust the delay (50ms) between each character
      // });
      // console.log(JSON.parse(response) , "sdfs");
    } else {
      if (props.userInput) {
        setShowBoarding(true);
      }
    }
  };
  const Download = () => {
    // const headers = new HttpHeaders();
    // let response = this.http.get(Img, {
    //   responseType: 'blob',
    //   headers,
    // });
    // const blob = new Blob();
    // const url = window.URL.createObjectURL(passportImg);
    const a = document.createElement("a");
    a.href = "../../../assets/img/passport.png";
    a.download = "download.png";
    a.click();
    // window.URL.revokeObjectURL(../../../assets/img/passport.png);
  };

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [anotherOptions, setAnotherOptions] = useState([]);
  const [suggestedList, setSuggestedList] = useState([
    { value: "Loading..", disabled: true },
  ]);

  const getPanelValue = (searchText) => {
    console.log(searchText);
    return !searchText ? [] : suggestedList;
  };

  const onSelect = async (val, data) => {
    console.log("onSelect", data, options, val);
    if (val == "FROM") {
      setForm((val) => ({ ...val, from: data }));
      setForm((val) => ({
        ...val,
        from_iata: options?.filter(
          (el) => el.value.toString() == data?.toString()
        )[0]?.id,
      }));
    }

    if (val == "TO") {
      setForm((val) => ({ ...val, to: data }));
      setForm((val) => ({
        ...val,
        to_iata: options?.filter(
          (el) => el.value.toString() == data?.toString()
        )[0]?.id,
      }));
    }

    setOptions([]);
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <>
      {IsShown &&
        showTicket == false &&
        showForm == false &&
        showFlightList == false ? (
        // <div className="dashboard__logo">
        //   <div className="dashboard__logo-text">
        //     <img src={logo} alt="logo" />
        //     {props.type === "chat" ? (
        //       <div className="dashboard__title">
        //         Welcome to jarvis Chat bot!
        //       </div>
        //     ) : (
        //       <div className="dashboard__title">
        //         Welcome to jarvis Image Generation!
        //       </div>
        //     )}

        //     <div className="dashboard__para">
        //       Experience smooth and hassle-free communication with our intuitive
        //       chat application. Stay connected, share ideas, and collaborate
        //       effortlessly.
        //     </div>
        //     <div className="dashboard__list-content">
        //       <ul className="dashboard__list">
        //         <li>
        //           <span>
        //             <img src={messagecircle} alt="new" />
        //           </span>
        //           Natural Language Conversation
        //         </li>
        //         <li>
        //           <span>
        //             <img src={bookmark} alt="new" />
        //           </span>
        //           Knowledge Base
        //         </li>
        //         <li>
        //           <span>
        //             <img src={check} alt="new" />
        //           </span>
        //           Accurate Responses
        //         </li>
        //         <li>
        //           <span>
        //             <img src={trade} alt="new" />
        //           </span>
        //           Seamless Integration
        //         </li>
        //       </ul>
        //     </div>
        //   </div>
        // </div>
        <>
          <div className="dashboard__grid-list">
            <div className="dashboard__logo">
              <div className="dashboard__logo-text">
                <img src={logo} alt="logo" />
                {props.type === "chat" ? (
                  <div className="dashboard__title">
                    Welcome to jarvis Airline
                  </div>
                ) : (
                  <div className="dashboard__title">
                    Welcome to jarvis Image Generation!
                  </div>
                )}

                <div className="dashboard__para">
                  Hey There, I’m here to help you.
                </div>
                <div className="dashboard__card-airline">
                  <div
                    className="dashboard__card-box"
                    onClick={() => {
                      setShowForm(true);
                      console.log(showForm);
                    }}
                  >
                    <div className="dashboard__card-inner">
                      <div className="dashboard__card-inner-img-second">
                        <img src={photo} alt="" />
                        <h4>Book a Flight</h4>
                      </div>
                      <div className="arrow-img">
                        <img src={arrowRight} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="dashboard__card-box"
                    onClick={() => {
                      setjarvisForm(true);
                      props.data(true);
                      console.log(showForm);
                    }}
                  >
                    <div className="dashboard__card-inner">
                      <div className="dashboard__card-inner-img-second">
                        <img src={edu} alt="" />
                        <div className="dashboard__card-inner-coming-soon">
                          <h4>Chat With Jarvis</h4>
                          {/* <div className="dashboard__coming-soon-text">
                          (Coming soon)
                        </div> */}
                        </div>
                      </div>
                      <div className="arrow-img">
                        <img src={arrowRight} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="dashboard__card-box">
                    <div className="dashboard__card-inner">
                      <div className="dashboard__card-inner-img-second">
                        <img src={videoJarvis} alt="" />
                        <div className="dashboard__card-inner-coming-soon">
                          <h4>Flight Status</h4>
                          <div className="dashboard__coming-soon-text">
                            (Coming soon)
                          </div>
                        </div>
                      </div>
                      <div className="arrow-img">
                        <img src={arrowRight} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="dashboard__card-box">
                    <div className="dashboard__card-inner">
                      <div className="dashboard__card-inner-img-second">
                        <img src={code} alt="" />
                        <div className="dashboard__card-inner-coming-soon">
                          <h4>Baggage</h4>
                          <div className="dashboard__coming-soon-text">
                            (Coming soon)
                          </div>
                        </div>
                      </div>
                      <div className="arrow-img">
                        <img src={arrowRight} alt="" />
                      </div>
                    </div>
                  </div>
                  {/* <div className="dashboard__card-box">
                  <div className="dashboard__card-inner">
                    <div className="dashboard__card-inner-img-second">
                      <img src={audio} alt="" />
                      <h4>More</h4>
                    </div>
                    <div className="arrow-img">
                      <img src={arrowRight} alt="" />
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>

        </>
      ) : (
        <>
          <div className="dashboard__chatbox" id="chatbox" ref={chatboxRef}>
            {clear && (
              <div className={`dashboard__message-content animation-class`}>
                <div>
                  201 responses so far.
                  <span onClick={() => handleClearMessages()}>
                    Clear all messages
                  </span>
                </div>
                <div onClick={closeArrow}>
                  <img src={close} alt="" className="cursor" />
                </div>
              </div>
            )}

            {arrowDown && (
              <div onClick={arrowClick} className="dashboard__arrow-top">
                <img src={arrowup} alt="" />
              </div>
            )}

            {showBoardingPnr == true && (
              <div className="dashboard__response-text">
                {" "}
                Please provide your PNR{" "}
              </div>
            )}
            {(showTicket == true || showBoarding == true) && (
              <div className="dashboard__flightcard-small">
                <div className="dashboard__flightcard-body">
                  <div className="dashboard__trip-detail">
                    <h2 className="dashboard__trip-username">Karthikeyan</h2>
                    <div className="dashboard__trip-timeings">
                      <div className="dashboard__trip-time">14:05 PNG</div>
                      <div className="dashboard__trip-hr">---2hr 40m---</div>
                      <div className="dashboard__trip-time">16:45 KUL</div>
                    </div>
                    <div className="dashboard__flight-info">
                      <img src={flightIconlight} alt="flight" />
                      <div className="dashboard__trip-time">6E 882</div>
                      <div className="dashboard__trip-time">.Non-stop</div>
                    </div>
                    <div className="dashboard__trip-booking">
                      <Link to={Pdf} target="_blank" download>
                        <button className="dashboard__download-btn">
                          Download PDF
                        </button>
                      </Link>

                      <a href={Pdf} target="_blank">
                        <button className="dashboard__book-btn">View</button>
                      </a>
                      {/* <iframe src="../../../assets/img/Eticket_58063797.pdf" width="100%" height="600px" style="border: none;" allow="fullscreen"></iframe> */}
                      {/* <div className="dashboard__trip-view">View</div> */}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            )}

            {/* { showTicket == true &&
                            <div>
                  <div className="dashboard__card-row">
                    <div className="dashboard__flightcard-small">
                      <div className="dashboard__flightcard-body">
                        <div className="dashboard__trip-detail-booking ">
                          <div className="dashboard__icon-box">
                            <div className="cab-round">
                              <img src={hotel} alt="" />
                            </div>
                          </div>
                          <h2 className="dashboard__trip-username">Hotel</h2>
                          <div className="dashboard__trip-timeings">
                            <div className="dashboard__trip-time">Classic Suite</div>
                            <div className="dashboard__trip-hr">01 Room</div>
                          </div>
                          <div className="dashboard__flight-info">
                            <img src={flightIconlight} alt="flight" />
                            <div className="dashboard__trip-time">Thu, 18 sept - Fri, 20  sept</div>
                            <div className="dashboard__trip-time">.Non-stop</div>
                          </div>
                          <div className="dashboard__trip-booking-select">
                            <div className="dashboard__trip-view">75 MYR</div>
                            <button className="dashboard__book-btn" onClick={(e)=>{
                              setselect(true)
                            }}>Select</button>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <div className="dashboard__flightcard-small">
                      <div className="dashboard__flightcard-body">
                        <div className="dashboard__trip-detail-booking">
                          <div className="dashboard__icon-box">
                            <div className="cab-round">
                              <img src={arrowRight} alt="" />
                            </div>
                            <div className="cab-round">
                              <img src={cab} alt="" />
                            </div>
                          </div>
                          <h2 className="dashboard__trip-username">Hotel + Cab Service</h2>
                          <div className="dashboard__trip-timeings">
                            <div className="dashboard__trip-time">Classic Suite</div>
                            <div className="dashboard__trip-hr">01 Room</div>
                          </div>
                          <div className="dashboard__flight-info">
                            <img src={flightIconlight} alt="flight" />
                            <div className="dashboard__trip-time">Thu, 18 sept - Fri, 20 sept</div>
                            <div className="dashboard__trip-time">.Non-stop</div>
                          </div>
                          <div className="dashboard__trip-booking-select">
                            <div className="dashboard__trip-view">40 MYR</div>
                            <button className="dashboard__book-btn" onClick={((e)=>{
                              setShowCar(true)
                            })}>Select</button>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>} */}
            {/* { (showSelect == true  || showCar == true )&& <div>
                    <Timeline
                      items={[
                        {
                          children: (
                            <>
                              <h4 className="timeline-head">Onward Flight</h4>
                              <div className="dashboard__flightcard-title">
                                <div className="dashboard__trip-info">Penang</div>
                                <img className="dashboard__icon" src={flightIcon} alt="chat" />
                                <div className="dashboard__trip-info">Kuala Lumpur</div>
                                <div className="dashboard__trip-date">Thu, 14 sep</div>
                              </div>
                              <div className="dashboard__flightcard-body">
                                <div className="dashboard__trip-detail">
                                  <div className="dashboard__trip-timeings">
                                    <div className="dashboard__trip-time">14:05 PNG</div>
                                    <div className="dashboard__trip-hr">---2hr 40m---</div>
                                    <div className="dashboard__trip-time">16:45 KUL</div>
                                  </div>
                                  <div className="dashboard__flight-info">
                                    <img src={flightIconlight} alt="flight" />
                                    <div className="dashboard__trip-time">6E 882</div>
                                    <div className="dashboard__trip-time">.Non-stop</div>
                                  </div>
                                </div>
                                <div className="dashboard__trip-booking">
                                  <img src={success} alt="" />
                                  <div className="dashboard__trip-time">Booked</div>

                                </div>
                              </div>
                              <Divider />
                            </>
                          ),
                        },
                        {
                          children: (
                            <>
                            { showCar == true && <div>
                            <h4 className="timeline-head">Transfer ( Car rental )</h4>
                              <div className="cab-content">
                                <div className="cab-content-inner">
                                  <div className="cab-round">
                                    <img src={cab} alt="" />
                                  </div>
                                  <div>
                                    <h4 className="timeline-cab">Jumail Car rental</h4>
                                    <div className="cap-location">Sept 14 | Airport to hotel in Kuala Lumpur | 1Hr</div>
                                    <div className="cap-confirm">
                                      <div className="cap-location">Car confirmation</div>
                                      <h4 className="timeline-head">AZNP01VB</h4>
                                    </div>


                                  </div>
                                </div>
                                <div className="dashboard__trip-booking">
                                  <img src={success} alt="" />
                                  <div className="dashboard__trip-time">Booked</div>
                                </div>

                              </div>
                              <Divider />

                            </div>
                              }
                            </>
                          ),
                        },
                        {

                          children: (
                            <>
                           { showSelect == true && <div>
                            <h4 className="timeline-head">Hotel Stay</h4>
                              <div className="cab-content">
                                <div className="cab-content-inner">
                                  <div className="cab-round">
                                    <img src={hotel} alt="" />
                                  </div>
                                  <div>
                                    <h4 className="timeline-cab">The Scott Garden Service Suite</h4>
                                    <div className="cap-location">KL101 Service Suite. The Scott Garden KL., <br /> Malaysia | <span>+60183707244</span> </div>
                                  </div>
                                </div>
                                <div className="dashboard__trip-booking">
                                  <img src={success} alt="" />
                                  <div className="dashboard__trip-time">Booked</div>
                                </div>

                              </div>
                              <div className="cap-content-check">
                                <div className="cap-confirm">
                                  <div className="cap-location">Check-in</div>
                                  <h4 className="timeline-cab">Thu, 14 Sep</h4>
                                  <div className="cap-location">11.00pm</div>
                                </div>
                                <div className="cap-confirm">
                                  <div className="cap-location">Check-out</div>
                                  <h4 className="timeline-cab">Fri, 15 Sep</h4>
                                  <div className="cap-location">11.00pm</div>
                                </div>
                                <div className="cap-confirm">
                                  <div className="cap-location">Booking ID</div>
                                  <h4 className="timeline-cab">AZNP01VB</h4>
                                </div>
                              </div>
                              <div className="cap-content-check">
                                <div className="cap-confirm">
                                  <div className="cap-location">Room Type</div>
                                  <h4 className="timeline-cab">Classic Suite</h4>
                                </div>
                                <div className="cap-confirm">
                                  <div className="cap-location">Rooms</div>
                                  <h4 className="timeline-cab">01</h4>
                                </div>
                                <div className="cap-confirm">
                                  <div className="cap-location">Booking ID</div>
            <h4 className="timeline-cab">AZNP01VB</h4>
                                </div>
                              </div>
                              <Divider />
                            </div>}
                              
                            </>
                          ),
                        },

                        // {
                        //   children: (
                        //     <>
                        //       <h4 className="timeline-head">Transfer ( Car rental )</h4>
                        //       <div className="cab-content">
                        //         <div className="cab-content-inner">
                        //           <div className="cab-round">
                        //             <img src={cab} alt="" />
                        //           </div>
                        //           <div>
                        //             <h4 className="timeline-cab">Jumail Car rental</h4>
                        //             <div className="cap-location">Sept 14 | Airport to hotel in Kuala Lumpur | 1Hr</div>
                        //             <div className="cap-confirm">
                        //               <div className="cap-location">Car confirmation</div>
                        //               <h4 className="timeline-head">AZNP01VB</h4>
                        //             </div>


                        //           </div>
                        //         </div>
                        //         <div className="dashboard__trip-booking">
                        //           <img src={success} alt="" />
                        //           <div className="dashboard__trip-time">Booked</div>
                        //         </div>

                        //       </div>
                        //       <Divider />
                        //     </>
                        //   ),
                        // },
                        // {
                        //   children: (
                        //     <>
                        //       <h4 className="timeline-head">Onward Flight</h4>
                        //       <div className="dashboard__flightcard-title">
                        //         <div className="dashboard__trip-info">Penang</div>
                        //         <img className="dashboard__icon" src={flightIcon} alt="chat" />
                        //         <div className="dashboard__trip-info">Kuala Lumpur</div>
                        //         <div className="dashboard__trip-date">Thu, 14 sep</div>
                        //       </div>
                        //       <div className="dashboard__flightcard-body">
                        //         <div className="dashboard__trip-detail">
                        //           <div className="dashboard__trip-timeings">
                        //             <div className="dashboard__trip-time">14:05 PNG</div>
                        //             <div className="dashboard__trip-hr">---2hr 40m---</div>
                        //             <div className="dashboard__trip-time">16:45 KUL</div>
                        //           </div>
                        //           <div className="dashboard__flight-info">
                        //             <img src={flightIconlight} alt="flight" />
                        //             <div className="dashboard__trip-time">6E 882</div>
                        //             <div className="dashboard__trip-time">.Non-stop</div>
                        //           </div>
                        //         </div>
                        //         <div className="dashboard__trip-booking">
                        //           <img src={success} alt="" />
                        //           <div className="dashboard__trip-time">Confirmed</div>

                        //         </div>
                        //       </div>
                        //     </>
                        //   ),
                        // },
                      ]}
                    />
                  </div>} */}

            {showForm !== true &&
              jarvisForm == true &&
              // chatHistory?.map((Chatdata, index, array) => (<pre>{Chatdata?.text}</pre> ))

              chatHistory.map((Chatdata, index, array) => (
                <div key={index} id="content-div">
                  <div className="dashboard__sender">
                    {index !== 0 && (
                      <div className="dashboard__sender-container">
                        <div className="flex">
                          <div className="dashboard__profile">
                            <h1>JR</h1>
                          </div>
                          <div className="dashboard__sender-text">
                            <p ref={userTextRef}>{Chatdata.text}</p>
                          </div>
                        </div>
                        <div className="dashboard__copy"></div>
                      </div>
                    )}
                  </div>
                  <div className="dashboard__response">
                    <div className="dashboard__response-container">
                      <div className="flex">
                        <div className="dashboard__response-name">
                          <img src={avatar} alt="new" />
                        </div>
                        <div
                          className="dashboard__response-text"
                          ref={aiTextRef}
                        >
                          {Chatdata.isLoading ? (
                            <div className="blink"></div>
                          ) : (
                            <>
                              <div>
                                {showTicket == false && (
                                  <div className="response-text-secont">
                                    {Chatdata.text?.length > 0 && (
                                      <h4>{Chatdata?.response}</h4>
                                    )}
                                  </div>
                                )}

                                {/* <Radio.Group defaultValue="a" size="large" onChange={(e) => {
                                chooseDetails(e.target.value)
                              }}>
                                <Radio.Button value="a">Book Flight</Radio.Button>
                            <Radio.Button value="b">Flight Info</Radio.Button>
                            <Radio.Button value="c">Payment/Refund</Radio.Button>
                            <Radio.Button value="d">Feedback</Radio.Button>
                            <Radio.Button value="e">More</Radio.Button>
                                {(Chatdata?.response?.options?.length > 0) && Chatdata?.response?.options?.map((el, i) => {
                                  return (
                                    <>
                                      <Radio.Button key={i} value={el}>{el}</Radio.Button>
                                    </>
                                  )
                                })}

                              </Radio.Group>
                              <Radio.Group
                                defaultValue="a"
                                style={{
                                  marginTop: 16,
                                }}
                              ></Radio.Group>
                              <div className="response-text-secont">
                                { Chatdata.text?.length > 1 && Chatdata?.response?.text.map((el,i) => {
                                    return (
                                        <h4>{Chatdata?.response?.text[i + 1]}</h4>
                                    )
                                })   
                            } 
                              </div> */}
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      {/* <div className="dashboard__copy">
                      <Tooltip title={tooltiptext}>
                        <img
                          src={copy}
                          alt="text"
                          onClick={() => resHandleCopyClick(index)}
                        />
                      </Tooltip>
                    </div> */}
                    </div>

                    {/* {  Chatdata?.response?.type == "flightselect" && <div>
                    <div className="dashboard__flightcard">
                      <div className="dashboard__flightcard-title">
                        <div className="dashboard__trip-info">{flightDetails?.departure}</div>
                        <img className="dashboard__icon" src={flightIcon} alt="chat" />
                        <div className="dashboard__trip-info">{flightDetails?.arrival}</div>
                        <div className="dashboard__trip-date">Thu ,18 sep</div>
                      </div>
                      <div className="dashboard__flightcard-overlay">
                        <div className="dashboard__flightcard-body">
                          <div className="dashboard__trip-detail">
                            <div className="dashboard__trip-timeings">
                              <div className="dashboard__trip-time">14:05 PNG</div>
                              <div className="dashboard__trip-hr">---2hr 40m---</div>
                              <div className="dashboard__trip-time">16:45 KUL</div>
                            </div>
                            <div className="dashboard__flight-info">
                              <img src={flightIconlight} alt="flight" />
                              <div className="dashboard__trip-time">6E 882</div>
                              <div className="dashboard__trip-time">.Non-stop</div>
                            </div>
                          </div>
                          <div className="dashboard__trip-booking">
                            <div className="dashboard__trip-distance">100.42 MYR</div>
                            <button className="dashboard__book-btn" onClick={(e) => {
                                chooseDetails("book")
                              }}>BOOK</button>
                          </div>
                        </div>
                    </div>
                  </div>
                  </div>}
                  
                  <div className="dashboard__flightcard">
                    <div className="dashboard__flightcard-body">
                      <div className="dashboard__trip-detail">
                        <div className="dashboard__trip-timeings">
                          <div className="dashboard__trip-time">14:05 PNG</div>
                          <div className="dashboard__trip-hr">---2hr 40m---</div>
                          <div className="dashboard__trip-time">16:45 KUL</div>
                        </div>
                        <div className="dashboard__flight-info">
                          <img src={flightIconlight} alt="flight" />
                          <div className="dashboard__trip-time">6E 882</div>
                          <div className="dashboard__trip-time">.Non-stop</div>
                        </div>
                      </div>
                      <div className="dashboard__trip-booking">
                        <img src={success} alt="" />
                        <button class="dashboard__change-btn">Change</button>
                      </div>
                    </div>
                  </div>
                
                  <div>
                    <div className="response-text-secont">
                      <h4>It was a pleasure speaking with you today. Have a great day!</h4>
                    </div>
                    <Radio.Group defaultValue="a" size="large">
                      <Radio.Button value="a">Book Flight</Radio.Button>
                      <Radio.Button value="b">Flight Info</Radio.Button>
                      <Radio.Button value="c">Payment/Refund</Radio.Button>
                      <Radio.Button value="d">Feedback</Radio.Button>
                      <Radio.Button value="e">More</Radio.Button>

                    </Radio.Group>
                    <Radio.Group
                      defaultValue="a"
                      style={{
                        marginTop: 16,
                      }}
                    ></Radio.Group>
                  </div> */}
                  </div>
                </div>
              ))}

            {showForm == true && (
              <div className="searchflight">
                <div className="searchflight__places-item" id="searchFlight">
                  <AutoComplete
                    options={options}
                    // style={{ width: 50% }}
                    //
                    onInput={(e) => {
                      airportSuggestions(e?.target?.value);
                    }}
                    onSelect={(e) => onSelect("FROM", e)}
                    onSearch={(text) => setOptions(getPanelValue(text))}
                    placeholder="From Where"
                    value={formVal.from}
                  />
                  <AutoComplete
                    options={options}
                    // style={{ width: 50% }}
                    onSelect={(e) => onSelect("TO", e)}
                    onSearch={(text) => setOptions(getPanelValue(text))}
                    onInput={(e) => {
                      airportSuggestions(e?.target?.value);
                    }}
                    placeholder="To Where"
                    value={formVal.to}
                    onChange={(e) =>
                      setForm((val) => ({ ...val, to: e?.target?.value }))
                    }
                  />
                  {/* <Input   type="text" placeholder="From Where" value={formVal.from} onChange={(e) => setForm((val) => ({...val , from:e?.target?.value}))}/> */}
                  {/* <Input  type="text" placeholder="To Where" value={formVal.to}  onChange={(e) => setForm((val) => ({...val , to:e?.target?.value}))} /> */}
                </div>
                <div className="searchflight__places-item" id="flightDate">
                  <DatePicker
                    className="searchflight__placeInputs"
                    value={formVal.depDate}
                    format={"YYYY-MM-DD"}
                    onChange={(date, dateString) => {
                      setForm((val) => ({
                        ...val,
                        depDate: date,
                        departureDate: dateString?.toString(),
                      }));
                    }}
                    size="large"
                    placeholder="Depart"
                  />
                  <DatePicker
                    className="searchflight__placeInputs"
                    value={formVal.returnDate}
                    format={"YYYY-MM-DD"}
                    onChange={(date, dateString) => {
                      setForm((val) => ({
                        ...val,
                        returnDate: date,
                        return: dateString?.toString(),
                      }));
                    }}
                    size="large"
                    placeholder="Return"
                  />
                  <Input
                    type="text"
                    placeholder="Passengers"
                    className="searchflight__passengerInput"
                    value={formVal.passengerCount}
                    onChange={(e) =>
                      setForm((val) => ({
                        ...val,
                        passengerCount: e?.target?.value,
                      }))
                    }
                  />
                </div>
                <div className="searchflight__places-item">
                  <Button
                    type="submit"
                    className="searchflight__submit-button"
                    disabled={loader}
                    loading={loader}
                    onClick={(e) => Searchflight(e, false, [])}
                  >
                    Search Flights
                  </Button>
                </div>
              </div>
            )}
            {showFlightList == true &&
              flightSelected !== true &&
              flightDetails?.length > 0 && (
                <div style={{ marginTop: "40px" }}>
                  {flightDetails?.map((data) => (
                    <div className="dashboard__flightcard">
                      <div className="dashboard__flightcard-title">
                        <div className="dashboard__trip-info">
                          {data?.departure}
                        </div>
                        <img
                          className="dashboard__icon"
                          alt="chat"
                        />
                        <div className="dashboard__trip-info">
                          {data?.arrival}
                        </div>
                        <div className="dashboard__trip-date">
                          {format(
                            new Date(data?.DepartureTime),
                            "yyyy/MM/dd kk:mm:ss"
                          )}
                        </div>
                      </div>
                      <div className="dashboard__flightcard-overlay">
                        <div className="dashboard__flightcard-body">
                          <div className="dashboard__trip-detail">
                            <div className="dashboard__trip-timeings">
                              <div className="dashboard__trip-time">
                                {new Date(data?.DepartureTime).getHours() +
                                  ":" +
                                  new Date(
                                    data?.DepartureTime
                                  ).getMinutes()}{" "}
                                {data?.DepartureAirport}
                              </div>
                              <div className="dashboard__trip-hr">
                                ---{data?.Duration?.replace("PT", "")}---
                              </div>
                              <div className="dashboard__trip-time">
                                {new Date(data?.ArrivalTime).getHours() +
                                  ":" +
                                  new Date(data?.ArrivalTime).getMinutes()}{" "}
                                {data?.ArrivalAirport}
                              </div>
                            </div>
                            <div className="dashboard__flight-info">
                              <img src={flightIconlight} alt="flight" />
                              <div className="dashboard__trip-time">
                                {data?.CarrierCode} {data?.AircraftCode}
                              </div>
                              <div className="dashboard__trip-time">
                                {" "}
                                {data?.NumberofStops == 0
                                  ? ".Non-stop"
                                  : data?.NumberofStops}
                              </div>
                            </div>
                          </div>
                          <div className="dashboard__trip-booking">
                            <div className="dashboard__trip-distance">
                              {data?.GrandTotal} {data?.Currency}
                            </div>
                            <button
                              className="dashboard__book-btn"
                              onClick={(e) => {
                                // chooseDetails("book")
                                selectedFlight(data);
                              }}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                        {/* <div className="dashboard__flightcard-body">
                        <div className="dashboard__trip-detail">
                          <div className="dashboard__trip-timeings">
                            <div className="dashboard__trip-time">14:05 PNG</div>
                            <div className="dashboard__trip-hr">---2hr 40m---</div>
                            <div className="dashboard__trip-time">16:45 KUL</div>
                          </div>
                          <div className="dashboard__flight-info">
                            <img src={flightIconlight} alt="flight" />
                            <div className="dashboard__trip-time">6E 882</div>
                            <div className="dashboard__trip-time">.Nn-stop</div>
                          </div>
                        </div>
                        <div className="dashboard__trip-booking">
                          <div className="dashboard__trip-distance">100.42 MYR</div>
                          <button className="dashboard__book-btn">Book</button>
                        </div>
                      </div> */}
                        {/* <div className="dashboard__flightcard-body">
                        <div className="dashboard__trip-detail">
                          <div className="dashboard__trip-timeings">
                            <div className="dashboard__trip-time">14:05 PNG</div>
                            <div className="dashboard__trip-hr">---2hr 40m---</div>
                            <div className="dashboard__trip-time">16:45 KUL</div>
                          </div>
                          <div className="dashboard__flight-info"> 
                            <img src={flightIconlight} alt="flight" />
                            <div className="dashboard__trip-time">6E 882</div>
                            <div className="dashboard__trip-time">.Non-stop</div>
                          </div>
                        </div>
                        <div className="dashboard__trip-booking">
                          <div className="dashboard__trip-distance">100.42 MYR</div>
                          <button className="dashboard__book-btn">Book</button>
                        </div>
                      </div> */}
                      </div>
                      {/* <div className="dashboard__flightcard-footer">
                        Select Flight
                    </div> */}
                    </div>
                  ))}
                </div>
              )}

            {flightSelected == true && (
              <div className="dashboard__flightcard-selected">
                <div className="dashboard__flightcard-title">
                  <div className="dashboard__trip-info">
                    {selectedFlightDetails?.departure}
                  </div>
                  <img
                    className="dashboard__icon"
                    src={flightIcon}
                    alt="chat"
                  />
                  <div className="dashboard__trip-info">
                    {selectedFlightDetails?.arrival}
                  </div>
                  <div className="dashboard__trip-date">
                    {format(
                      new Date(selectedFlightDetails?.DepartureTime),
                      "yyyy/MM/dd kk:mm:ss"
                    )}
                  </div>
                </div>
                <div className="dashboard__flightcard-body">
                  <div className="dashboard__trip-detail">
                    <div className="dashboard__trip-timeings">
                      <div className="dashboard__trip-time">
                        {new Date(
                          selectedFlightDetails?.DepartureTime
                        ).getHours() +
                          ":" +
                          new Date(
                            selectedFlightDetails?.DepartureTime
                          ).getMinutes()}{" "}
                        {selectedFlightDetails?.DepartureAirport}
                      </div>
                      <div className="dashboard__trip-hr">
                        ---{selectedFlightDetails?.Duration?.replace("PT", "")}
                        ---
                      </div>
                      <div className="dashboard__trip-time">
                        {new Date(
                          selectedFlightDetails?.ArrivalTime
                        ).getHours() +
                          ":" +
                          new Date(
                            selectedFlightDetails?.ArrivalTime
                          ).getMinutes()}{" "}
                        {selectedFlightDetails?.ArrivalAirport}
                      </div>
                    </div>
                    <div className="dashboard__flight-info">
                      <img src={flightIconlight} alt="flight" />
                      <div className="dashboard__trip-time">
                        {selectedFlightDetails?.CarrierCode}{" "}
                        {selectedFlightDetails?.AircraftCode}
                      </div>
                      <div className="dashboard__trip-time">
                        {" "}
                        {selectedFlightDetails?.NumberofStops == 0
                          ? ".Non-stop"
                          : selectedFlightDetails?.NumberofStops}
                      </div>
                    </div>
                  </div>
                  <div className="dashboard__trip-booking">
                    <div className="dashboard__trip-distance">
                      {selectedFlightDetails?.GrandTotal}{" "}
                      {selectedFlightDetails?.Currency}
                    </div>
                    <button className="dashboard__change-btn">Selected</button>
                  </div>
                </div>
                <div className="dashboard__fight-services">
                  <div className="dashboard__response-text">
                    Do you need other services ?
                  </div>
                  <Radio.Group
                    defaultValue="NO"
                    size="large"
                    onChange={(e) => {
                      if (e?.target?.value == "YES") {
                        setselect(true);
                        console.log(selectedFlightDetails?.arrival);
                        getHotels(selectedFlightDetails?.ArrivalAirport);
                      } else {
                        setselect(false);
                      }
                    }}
                  >
                    <Radio.Button value="YES">Yes</Radio.Button>
                    <Radio.Button value="NO">No</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            )}

            {showSelect == true && (
              <div>
                <div className="dashboard__card-row">
                  <div className="dashboard__flightcard-small">
                    <div className="dashboard__flightcard-body">
                      <div className="dashboard__trip-detail-booking ">
                        <div className="dashboard__icon-box">
                          <div className="cab-round">
                            <img src={hotel} alt="" />
                          </div>
                        </div>
                        <h2 className="dashboard__trip-username">Hotel</h2>
                        <div className="dashboard__trip-timeings">
                          <div className="dashboard__trip-time">
                            Classic Suite
                          </div>
                          <div className="dashboard__trip-hr">01 Room</div>
                        </div>
                        <div className="dashboard__flight-info">
                          <img src={flightIconlight} alt="flight" />
                          <div className="dashboard__trip-time">
                            Thu, 18 sept - Fri, 20 sept
                          </div>
                          <div className="dashboard__trip-time">.Non-stop</div>
                        </div>
                        <div className="dashboard__trip-booking-select">
                          <div className="dashboard__trip-view">75 MYR</div>
                          <button
                            className="dashboard__book-btn"
                            onClick={(e) => { }}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="dashboard__flightcard-small">
                    <div className="dashboard__flightcard-body">
                      <div className="dashboard__trip-detail-booking">
                        <div className="dashboard__icon-box">
                          <div className="cab-round">
                            <img src={arrowRight} alt="" />
                          </div>
                          <div className="cab-round">
                            <img src={cab} alt="" />
                          </div>
                        </div>
                        <h2 className="dashboard__trip-username">
                          Hotel + Cab Service
                        </h2>
                        <div className="dashboard__trip-timeings">
                          <div className="dashboard__trip-time">
                            Classic Suite
                          </div>
                          <div className="dashboard__trip-hr">01 Room</div>
                        </div>
                        <div className="dashboard__flight-info">
                          <img src={flightIconlight} alt="flight" />
                          <div className="dashboard__trip-time">
                            Thu, 18 sept - Fri, 20 sept
                          </div>
                          <div className="dashboard__trip-time">.Non-stop</div>
                        </div>
                        <div className="dashboard__trip-booking-select">
                          <div className="dashboard__trip-view">40 MYR</div>
                          <button
                            className="dashboard__book-btn"
                            onClick={(e) => {
                              setShowCar(true);
                            }}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* {  <div>
                    <Timeline
                      items={[
                        {
                          children: (
                            <>
                              <h4 className="timeline-head">Onward Flight</h4>
                              <div className="dashboard__flightcard-title">
                                <div className="dashboard__trip-info">Penang</div>
                                <img className="dashboard__icon" src={flightIcon} alt="chat" />
                                <div className="dashboard__trip-info">Kuala Lumpur</div>
                                <div className="dashboard__trip-date">Thu, 14 sep</div>
                              </div>
                              <div className="dashboard__flightcard-body">
                                <div className="dashboard__trip-detail">
                                  <div className="dashboard__trip-timeings">
                                    <div className="dashboard__trip-time">14:05 PNG</div>
                                    <div className="dashboard__trip-hr">---2hr 40m---</div>
                                    <div className="dashboard__trip-time">16:45 KUL</div>
                                  </div>
                                  <div className="dashboard__flight-info">
                                    <img src={flightIconlight} alt="flight" />
                                    <div className="dashboard__trip-time">6E 882</div>
                                    <div className="dashboard__trip-time">.Non-stop</div>
                                  </div>
                                </div>
                                <div className="dashboard__trip-booking">
                                  <img src={success} alt="" />
                                  <div className="dashboard__trip-time">Booked</div>

                                </div>
                              </div>
                              <Divider />
                            </>
                          ),
                        },
                        {
                          children: (
                            <>
                            { showCar == true && <div>
                            <h4 className="timeline-head">Transfer ( Car rental )</h4>
                              <div className="cab-content">
                                <div className="cab-content-inner">
                                  <div className="cab-round">
                                    <img src={cab} alt="" />
                                  </div>
                                  <div>
                                    <h4 className="timeline-cab">Jumail Car rental</h4>
                                    <div className="cap-location">Sept 14 | Airport to hotel in Kuala Lumpur | 1Hr</div>
                                    <div className="cap-confirm">
                                      <div className="cap-location">Car confirmation</div>
                                      <h4 className="timeline-head">AZNP01VB</h4>
                                    </div>


                                  </div>
                                </div>
                                <div className="dashboard__trip-booking">
                                  <img src={success} alt="" />
                                  <div className="dashboard__trip-time">Booked</div>
                                </div>

                              </div>
                              <Divider />

                            </div>
                              }
                            </>
                          ),
                        },
                        {

                          children: (
                            <>
                           { <div>
                            <h4 className="timeline-head">Hotel Stay</h4>
                              <div className="cab-content">
                                <div className="cab-content-inner">
                                  <div className="cab-round">
                                    <img src={hotel} alt="" />
                                  </div>
                                  <div>
                                    <h4 className="timeline-cab">The Scott Garden Service Suite</h4>
                                    <div className="cap-location">KL101 Service Suite. The Scott Garden KL., <br /> Malaysia | <span>+60183707244</span> </div>
                                  </div>
                                </div>
                                <div className="dashboard__trip-booking">
                                  <img src={success} alt="" />
                                  <div className="dashboard__trip-time">Booked</div>
                                </div>

                              </div>
                              <div className="cap-content-check">
                                <div className="cap-confirm">
                                  <div className="cap-location">Check-in</div>
                                  <h4 className="timeline-cab">Thu, 14 Sep</h4>
                                  <div className="cap-location">11.00pm</div>
                                </div>
                                <div className="cap-confirm">
                                  <div className="cap-location">Check-out</div>
                                  <h4 className="timeline-cab">Fri, 15 Sep</h4>
                                  <div className="cap-location">11.00pm</div>
                                </div>
                                <div className="cap-confirm">
                                  <div className="cap-location">Booking ID</div>
                                  <h4 className="timeline-cab">AZNP01VB</h4>
                                </div>
                              </div>
                              <div className="cap-content-check">
                                <div className="cap-confirm">
                                  <div className="cap-location">Room Type</div>
                                  <h4 className="timeline-cab">Classic Suite</h4>
                                </div>
                                <div className="cap-confirm">
                                  <div className="cap-location">Rooms</div>
                                  <h4 className="timeline-cab">01</h4>
                                </div>
                                <div className="cap-confirm">
                                  <div className="cap-location">Booking ID</div>
            <h4 className="timeline-cab">AZNP01VB</h4>
                                </div>
                              </div>
                              <Divider />
                            </div>}
                              
                            </>
                          ),
                        },

                        {
                          children: (
                            <>
                              <h4 className="timeline-head">Transfer ( Car rental )</h4>
                              <div className="cab-content">
                                <div className="cab-content-inner">
                                  <div className="cab-round">
                                    <img src={cab} alt="" />
                                  </div>
                                  <div>
                                    <h4 className="timeline-cab">Jumail Car rental</h4>
                                    <div className="cap-location">Sept 14 | Airport to hotel in Kuala Lumpur | 1Hr</div>
                                    <div className="cap-confirm">
                                      <div className="cap-location">Car confirmation</div>
                                      <h4 className="timeline-head">AZNP01VB</h4>
                                    </div>


                                  </div>
                                </div>
                                <div className="dashboard__trip-booking">
                                  <img src={success} alt="" />
                                  <div className="dashboard__trip-time">Booked</div>
                                </div>

                              </div>
                              <Divider />
                            </>
                          ),
                        },
                        // {
                        //   children: (
                        //     <>
                        //       <h4 className="timeline-head">Onward Flight</h4>
                        //       <div className="dashboard__flightcard-title">
                        //         <div className="dashboard__trip-info">Penang</div>
                        //         <img className="dashboard__icon" src={flightIcon} alt="chat" />
                        //         <div className="dashboard__trip-info">Kuala Lumpur</div>
                        //         <div className="dashboard__trip-date">Thu, 14 sep</div>
                        //       </div>
                        //       <div className="dashboard__flightcard-body">
                        //         <div className="dashboard__trip-detail">
                        //           <div className="dashboard__trip-timeings">
                        //             <div className="dashboard__trip-time">14:05 PNG</div>
                        //             <div className="dashboard__trip-hr">---2hr 40m---</div>
                        //             <div className="dashboard__trip-time">16:45 KUL</div>
                        //           </div>
                        //           <div className="dashboard__flight-info">
                        //             <img src={flightIconlight} alt="flight" />
                        //             <div className="dashboard__trip-time">6E 882</div>
                        //             <div className="dashboard__trip-time">.Non-stop</div>
                        //           </div>
                        //         </div>
                        //         <div className="dashboard__trip-booking">
                        //           <img src={success} alt="" />
                        //           <div className="dashboard__trip-time">Confirmed</div>

                        //         </div>
                        //       </div>
                        //     </>
                        //   ),
                        // },
                      ]}
                    />
                  </div>} */}
          </div>
          {/* { (showForm !== true ) && (
              <div className={`dashboard__message-content animation-class`}>
                <div>
                  <span onClick={() => {navigate('/');
                window.location.reload();}
                }>
                    Go To Home
                  </span>
                </div>
                <div onClick={closeArrow}>
                  <img src={close} alt="" className="cursor" />
                </div>
              </div>
            )} */}
          {!IsShown && showArrow && (
            <div
              id="arrow-id"
              className="dashboard__arrow-down"
              onClick={handleArrowDownClick}
            >
              <img src={arrow} alt="" />
            </div>
          )}
        </>
      )}
    </>
  );
}
