import "../Dashboard/Dashboard.scss";
import logo from "../../../assets/img/logo.svg";
import send from "../../../assets/img/svg/send-line.svg";
import {
  Form,
  Input,
  notification,
  Button,
  Tooltip,
  message,
  Upload,
  Progress,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import chat from "../../../assets/img/svg/chat-black.svg";
import chatwhite from "../../../assets/img/svg/chat-white.svg";
import chatgray from "../../../assets/img/svg/chat-gray.svg";
import upload from "../../../assets/img/svg/upload.svg";
import imgwhite from "../../../assets/img/svg/img-white.svg";
import imgBlack from "../../../assets/img/svg/chat-black.svg";
import imggray from "../../../assets/img/svg/img-gray.svg";
import dataimg from "../../../assets/img/svg/dataimg-gray.svg";

import deleteimg from "../../../assets/img/svg/delete.svg";

import { AiBots } from "../../../services/aiBot";
import { useNavigate } from "react-router-dom";

export default function SearchButton(props) {
  //   const [searchButton, setSearchButton] = useState(false);

  const [form] = Form.useForm();
  const [userInput, setUserInput] = useState("");
  const { aiFile, storeDb, textlen } = AiBots();
  const [OwnbotStatus, setOwnbotStatus] = useState(false);
  const [IsLoadingBot, setIsLoadingBot] = useState(false);
  const [searchDisable, setSearchDisable] = useState(false);

  useEffect(() => {
    if (props.FileText === "" && props.dataText == "" && props.UrlText == "") {
      setSearchDisable(true);
    } else {
      setSearchDisable(false);
    }
    textlen().then((res) => {
      if (res.data.reply === 3) {
        console.log("if", res.data.reply);
        setOwnbotStatus(true);
      }
    });
  }, [props.FileText, props.UrlText, props.dataText, OwnbotStatus]);

  const handleSubmit = () => {
    form.resetFields();
    props.onUserInput(userInput);
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };
  const sendProps = (data) => {
    setOwnbotStatus(data);
    props.onOwnbotStatus(data);
    setIsLoadingBot(false);
  };
  const handleCreateBot = async () => {
    setIsLoadingBot(true);
    console.log(props);
    console.log(props.FileText);
    console.log(props.DataGenerationTab);

    if (props.DataGenerationTab === "File") {
      console.log(props.FileText);
      await storeDb({ Text: props.FileText })
        .then((response) => {
          console.log(response);
          sendProps(true);
          window.localStorage.setItem("file", 1);
        })
        .catch((error) => {
          console.error("Error", error);
          sendProps(false);
        });
    } else if (props.DataGenerationTab === "Text") {
      await storeDb({ Text: props.dataText })
        .then((response) => {
          console.log(response);
          sendProps(true);
        })
        .catch((error) => {
          console.error("Error", error);
          sendProps(false);
        });
    } else {
      await storeDb({ Text: props.UrlText })
        .then((response) => {
          console.log(response);
          sendProps(true);
        })
        .catch((error) => {
          console.error("Error", error);
          sendProps(false);
        });
    }
  };
  return (
    <div className="dashboard__card-input fixed-btn" id="submit-btn">
      {props.type === "dataGeneration" && !OwnbotStatus ? (
        <Button
          className="dashboard__chat-btn"
          onClick={handleCreateBot}
          disabled={searchDisable}
          loading={IsLoadingBot}
        >
          Create Chat Bot
        </Button>
      ) : (
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="note" className="m-0">
            <Input
              autoFocus
              //   disabled={searchButton}
              autocomplete="off"
              placeholder="Book a flight from..."
              className="dashboard__input-box-search"
              onChange={handleInputChange}
              value={userInput}
              autoSize={{
                minRows: 1.5,
                maxRows: 5,
              }}
              suffix={
                <Button
                  //   disabled={searchButton}
                  className="dashboard__send-textarea"
                  type="primary"
                  htmlType="submit"
                >
                  {<img src={send} alt="" />}
                </Button>
              }
            />
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
