import "../../assets/scss/auth.scss";
import { Button, Form, Input, Row, Col, notification } from "antd";
import logo from "../../assets/img/logo-login-black.svg";
import eyeopen from "../../assets/img/eye-open.svg";
import eyeactive from "../../assets/img/eye-active.svg";
import logowhite from "../../assets/img/logo-white.svg";
import React, { useState } from "react";
import { AiBots } from "../../services/aiBot";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login } = AiBots();

  const openNotification = (
    notification_type,
    notification_message,
    notification_description
  ) => {
    notification.open({
      type: notification_type,
      message: notification_message,
      description: notification_description,
      duration: 2,
    });
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    // Perform any necessary actions with the retrieved values
    console.log("Email:", email);
    console.log("Password:", password);

    await login({
      email: email,
      password: password,
    })
      // .then((res) => res.json())
      .then((response) => {
        console.log(response.data);
        console.log(response.data.apiKey);
        window.localStorage.setItem("UserId", email);
        openNotification(
          "success",
          response.data.message,
          response.data.description
        );
        localStorage.setItem("keys", response.data.apiKey);
        navigate("/");
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          openNotification(
            "error",
            "Network Error",
            "There was an error connecting to the server. Please try again later."
          );
        } else {
          openNotification(
            "error",
            error.response.data.message,
            error.response.data.description
          );
        }
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <section className="auth">
        <Row>
          <Col xs={0} sm={0} md={0} lg={12} xl={12}>
            <div className="auth__conrainer-left">
              <div className="auth__left-inner">
                <img src={logowhite} alt="" />
                <h2>Seamless Chat for Effortless Communication</h2>
                {/* <div className="blink"></div> */}
                <p>
                  Experience smooth and hassle-free communication with our
                  intuitive chat application. Stay connected, share ideas, and
                  collaborate effortlessly.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="auth__container">
              <div className="auth__container-card">
                <div className="auth__logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="auth__formcontainer">
                  <div className="auth__form-title">Login</div>
                  <div className="auth__form-para">
                    Kindly ensure that you fill in the correct login details in
                    the fields below.
                  </div>
                  <div id="login-form">
                    <Form onFinish={handleSubmit}>
                      <label htmlFor="email" className="auth__label-text">
                        Email
                      </label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email address",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                        ]}
                      >
                        <Input
                          value={email}
                          onChange={handleEmailChange}
                          placeholder=""
                          className="auth__input-box"
                        />
                      </Form.Item>
                      <label htmlFor="password" className="auth__label-text">
                        Password
                      </label>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your password",
                          },
                        ]}
                      >
                        <Input
                          value={password}
                          onChange={handlePasswordChange}
                          className="auth__input-box"
                          type={showPassword ? "text" : "password"}
                          suffix={
                            <img
                              src={showPassword ? eyeactive : eyeopen}
                              className="auth__icon"
                              alt="text"
                              onClick={togglePasswordVisibility}
                            /> /*<img src={active} className="auth__icon" alt="text" />*/
                          }
                        />
                      </Form.Item>
                      {/* <div className="auth__form-link">Forgot Password?</div> */}
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="auth__auth-btn"
                        >
                          Login
                        </Button>
                      </Form.Item>
                      <div className="auth__form-link">
                        Don’t you have an account?{" "}
                        <span
                          className="auth__text-link"
                          // onClick={() => navigate("/auth/signup")}
                        >
                          Sign Up{" "}
                        </span>{" "}
                        here
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <a class="marquee" href="https://futurengage.com/" target="_blank">
          <a href="https://futurengage.com/" target="_blank">
            <span>
              {" "}
              For more information and a detailed explanation about the services
              we provide, kindly visit www.futurengage.com
            </span>
            <span>
              {" "}
              For more information and a detailed explanation about the services
              we provide, kindly visit www.futurengage.com
            </span>
          </a>
        </a>
      </section>
    </>
  );
}
