import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";

export const Pagesrouter = () => {
  return (
    <>
      <Routes>
        <Route>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/home/:id" element={<Dashboard />}></Route>
          {/* <Route path="/dashboard" element={<Dashboard />}></Route> */}
        </Route>
      </Routes>
    </>
  );
};
