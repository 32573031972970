import { useEffect, useRef, useState } from "react";
import "./Header.scss";
import logo from "../../assets/img/header-logo-black.svg";
import logowhite from "../../assets/img/header-logo-white.svg";
import moon from "../../assets/img/moon.svg";
import sun from "../../assets/img/svg/sun.svg";

import { Dropdown, Space } from "antd";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const [tabActive, setTabActive] = useState(true);
  const navigate = useNavigate();
  const [themeMode, setThemeMode] = useState("dark");
  const themeSwitch = useRef(true);
  useEffect(() => {
    if (!localStorage.getItem("UserId")) {
      console.log("UserId");
      navigate("./auth/login");
    }
    switchTheme(themeMode);
  }, [navigate]);

  const switchTheme = (event) => {
    changeThemeMode(event);
  };
  const changeThemeMode = (mode) => {
    themeSwitch.current = mode === "dark" ? true : false;
    localStorage.setItem("themeMode", mode);
    document.body.classList.add(mode === "light" ? "light" : "dark");
    document.body.classList.replace(themeMode, mode);
    setThemeMode(mode);
  };
  const openNotification = (
    notification_type,
    notification_message,
    notification_description
  ) => {
    localStorage.removeItem("keys");
    localStorage.removeItem("UserId");
    navigate("/auth/login");
    window.location.reload();

    // notification.open({
    //   type: notification_type,
    //   message: notification_message,
    //   description: notification_description,
    //   duration: 2,
    // });
  };
  // const name = window.localStorage.getItem("UserId");
  // const firstLetter = name.charAt(0).toUpperCase();
  const items = [
    {
      label: (
        <>
          <div className="menu">
            <img
              src={require("../../assets/img/svg/log-out.svg").default}
              alt=""
            />
            <h1
              onClick={() =>
                openNotification(
                  "success",
                  "Logout Successful",
                  "You have been successfully logged out."
                )
              }
            >
              LogOut
            </h1>
          </div>
        </>
      ),
      key: "1",
    },
  ];

  return (
    <>
      <div className="header">
        <div className="header__logo" onClick={() => {navigate('/');
                window.location.reload();}
                }>
          <img src={themeMode === "dark" ? logowhite : logo} alt="" />
        </div>

        <div className="header__end">
          <div>
            <img
              className="cursor"
              onClick={() => switchTheme(themeMode === "light" ? "dark" : "light")}
              src={themeMode === "light" ? moon : sun}
              alt=""
            />
          </div>
          <div>
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <div onClick={(e) => e.preventDefault()}>
                <Space>
                  <div className="header__profile">
                    <h1>JR</h1>
                  </div>
                </Space>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};
