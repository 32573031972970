import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import UpiPayment from "./UpiPayment";
export default function Authrouter() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        {/* <Route path="/signup" element={<Signup />}></Route> */}
        <Route path="/upi" element={<UpiPayment />}></Route>
      </Routes>
    </>
  );
}
